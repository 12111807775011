<template>
  <div class="minbox">
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex='activeIndex' />
      </div>
    </div>
    <!-- banner区域 -->
    <div class="boxContentBg">
      <div class="banner">
        <div class="bannerTitle">
          综合金融服务平台
        </div>
        <div class="seeMore">
          解决金融机构放贷难而企业融资难的问题
        </div>
      </div>
    </div>
    <!-- 就业机会 -->
    <div class="boxContentTrem">
      <div class="opportunity">
        <div class="opportunityTitle">
          平台介绍
        </div>
        <div class="opportunityDescride">
          2017
          中央金融工作会议已为未来五年的金融工作方向定调，“金融服务实体经济”
          是其中重要的工作方向之一，如何解决地方金融空转问题成为各地方政府需要思考的问题。过去
          F2B
          的金融服务很难做起来，主要原因不外乎信用成本过高、金融机构的风控模式没有创新，以及利率定价模式过于僵化导致。
          然而大数据技术、人工智能技术以及区块链技术正在改变这一形势，我们致力应用这些新的技术，解决金融机构放贷难而企业融资难的问题。
        </div>
        <div class="opportunityContnets">
          <div class="opportunityContnetItem">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/service_intro_01@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">大数据建立数字金融</div>
            <div class="opportunityContnetTrem">
              我们设计了独特的融资初审大数据，透过企业融资的行为建立信用体系，以健全和完善金融机对风险的控制能力。
            </div>
          </div>
          <div class="opportunityContnetItem">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/service_intro_02@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">智能信用风险管理</div>
            <div class="opportunityContnetTrem">
              有了大数据，可以建立企业的用户画像，必根据聚类算法进行企业的信用风险分群，作为金融机构融资的参考，同时融资结果、坏涨情况也可以反馈进行智能学习
            </div>
          </div>
          <div class="opportunityContnetItem">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/service_intro_03@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">链链相连</div>
            <div class="opportunityContnetTrem">
              随著区块链技术的普及应用，越来越多的贸金场景会用到区块链，我们的平台可以接入更多真实而不可窜改的贸易数据，便于金融机构做为融资参考。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 对话 dialogue  -->
    <div class="boxContentDialogue">
      <div class="dialogue">
        <div class="expertDialogue">
          您是否希望和我们的专家对话？
        </div>
        <div class="technicalProposal">
          联系我们获得更多技术解决方案
        </div>
        <div class="contactUs">
          <div class="contactUsTitle">联系我们</div>
          <div class="contactUsImages">
            <img
              src="../../assets/homePageImg/home_banner_more@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
export default {
 data() {
    return {
      activeIndex: ''
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  created () {
    this.activeIndex = this.$route.query.id
  }
}
</script>
<style scoped lang="scss">
@import './currency.scss';
.boxContentBg {
  width: 100%;
  background: url('../../assets/platformServices/service_banner.png') no-repeat
    center center !important;
}
.seeMore {
  height: 33px !important;
}
.boxContentTrem {
  height: 701px !important;
  background: #fbfbfb !important;
}
.minbox .boxContentTrem .opportunity .opportunityDescride {
  height: 72px !important;
}
.opportunityContnets {
  margin: 64px 0 48px 0;
  display: flex;
  justify-content: space-between;
  .opportunityContnetItem {
    width: 366px;
    height: 392px;
    background: #ffffff;
    &:hover {
      box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.09);
      .opportunityContnetItemTitle {
        color: #0F4994;
      }
      .opportunityContnetTrem {
        color: #666666;
      }
    }
    .opportunityContnetItemImage {
      width: 140px;
      height: 126px;
      padding: 60px 0 36px 0;
      margin: 0 auto;
      > img {
        @extend .imgFill;
      }
    }
    .opportunityContnetItemTitle {
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      color: #181818;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
    }
    .opportunityContnetTrem {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
      padding: 20px 16px 0 16px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
</style>
